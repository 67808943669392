import passwordStrength from 'zxcvbn'

const passStrength = (password, userInputs) => {

  const passwordAnalysis = passwordStrength(password, userInputs)
  const ratings = [
    window.mpApp.$t('authentication.very-weak'),
    window.mpApp.$t('authentication.weak'),
    window.mpApp.$t('authentication.moderate'),
    window.mpApp.$t('authentication.good'),
    window.mpApp.$t('authentication.excellent'),
  ]
  const classes = ['color-danger', 'color-danger', 'color-warning', 'color-success', 'color-success']

  // Note: for internationalization of the warnings and suggestions, we would need to have translated all the
  // returned strings in https://github.com/dropbox/zxcvbn/blob/master/src/feedback.coffee
  return {
    rating: password.length ? ratings[passwordAnalysis.score] : '',
    class: classes[passwordAnalysis.score],
    feedback: passwordAnalysis.feedback,
    score: passwordAnalysis.score,
    sequence: passwordAnalysis.sequence,
  }
}

export { passStrength }
